.BulkLayoutActivationModel .modal-dialog .modal-content{
    border-radius: 1.3rem;
    background: #F2F2F2;
}
.d-mode .BulkLayoutActivationModel .modal-content{
    background: #2f353e;
}

.BulkLayoutActivationModel .modalheader button{
    font-size: 18px;
    margin-top: 2px;
}

.BulkLayoutActivationModel .modalheader .content{
    position: relative;
    display: flex;
    flex-direction: column;
    top: 18px;
}
.BulkLayoutActivationModel .modalheader .content .modal-title{
    font-size: 22px;
    font-weight: 700;
    color: #5128a0;
}
.d-mode .BulkLayoutActivationModel .modalheader .content .modal-title{
    font-size: 22px;
    font-weight: 700;
    color: #2CC990;
}
.BulkLayoutActivationModel .modalheader .content span{
    color: #4F4F4F;
    font-weight: 400;
    font-size: 16px;
}
.d-mode .BulkLayoutActivationModel .modalheader .content span{
    color: #AFAFAF;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents{
  padding: 14px 14px 0px 14px;
}

.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
} 
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .search-main-div{
  position: relative;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .search-main-div svg{
    position: absolute;
    right: 3px;
    top:6px;
    cursor: pointer;
  }
  
  .RTL .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .search-main-div svg{
   right: auto;
   left:3px;
  }
  


 .RTL .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header{
    padding-left: 0px;
    padding-right: 18px;
} 
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .searchbox{
   outline: none !important;
   box-shadow: none !important;
   border: none;
} 
.RTL .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .form-check-input {
    display: flex;
    flex-direction: row;
    gap: 33px;
    justify-content: center;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .form-check-label{
    font-weight: 600;
}
.d-mode .BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .form-check-label{
    color: #AFAFAF;
}

.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-header .form-check-input{
border-color: #5128A0;
cursor: pointer;
    
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    overflow-y: auto;
    max-height: 400px;
    height: calc(100vh - 400px);
    min-height: 187px;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails .form-check input{
    border-color: #5128A0;
    cursor: pointer;
    margin-bottom: 0px !important;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails span{
    color: #5128A0;
    font-weight: 600;
}
.d-mode .BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails span{
    color: #AFAFAF;
}
.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    border-radius: 6px;
    height: 38px;
    padding: 12px 10px 12px 10px;
    gap: 40px;
    position: relative;
}
.d-mode .BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails{
    background: #272b31;
 
}

.BulkLayoutActivationModel .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails .deleteIcon{
  position: absolute;
  right: 12px;
}
.RTL .BulkLayoutActivationModel-contents .BulkLayoutActivationModel-content .layoutDetails .deleteIcon{
    right: auto;
    left: 12px;
    transform: rotate(180deg);
  }
  

.BulkLayoutActivationModel .BulkLayoutActivationModel-contents  .BulkLayoutActivationModel-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #5128A0;
}
 .d-mode .BulkLayoutActivationModel .BulkLayoutActivationModel-contents  .BulkLayoutActivationModel-content::-webkit-scrollbar-thumb
{
	background-color: #2CC990;
}




.BulkLayoutActivationModel .modal-footer {
    display: flex;
    justify-content: space-between;
    border: none;
}
.BulkLayoutActivationModel .modal-footer .closebtn{
    padding: 3px 29px;
    background: #4F4F4F;
    border: none;
    font-weight: 600;
    border-radius: 25px;
}
.BulkLayoutActivationModel .modal-footer .updatebtn{
    padding: 3px 24px;
    background: #77DB61;
    color: #5128A0;
    border: none;
    font-weight: 600;
    border-radius: 25px;
    outline: none;
    box-shadow: none !important;
}