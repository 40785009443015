@font-face {
font-family: 'SimplerPro';
font-style: normal;
font-weight: 400;
src: url(../simplerpro/SimplerPro-Regular.otf);
}
@font-face {
font-family: 'SimplerPro';
font-style: normal;
font-weight: 600;
src: url(../simplerpro/SimplerPro-Bold.otf);
}