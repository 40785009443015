.BulkLayoutActivationModel .modal-content .modal-body .successModelbody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.BulkLayoutActivationModel .modal-content .modal-body .successModelbody span{
    font-size: 20px;
    font-weight: 700;
    color: #5128a0;
}
.d-mode .BulkLayoutActivationModel .modal-content .modal-body .successModelbody span{
    color: #2CC990;
}