$primary-color: #5128A0;
$primary-dark: #2cc990;
$primary-gradiant: linear-gradient(122.64deg, #5128A0 4.85%, #7743DC 96.83%);
$secondary-color: #754BC7;
$light-color: #9E7AE4;
$extralight-color: #C2AEEA;

$red-color: #EF5645;
$pink-color: #ED327A;
$orange-color: #F39C12;
$green-color: #77DB61;